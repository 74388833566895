import { Genre } from '../types/genre';
import { genreData } from '../data/genre-data'; 
import { getRandomPosition } from '../utils/number-utils';

export function getAllGenres(): Genre[] {
    return genreData;
}

export function generateGenre(): Genre {
    const position = getRandomPosition(genreData.length);
    return genreData[position];
}

