import React from 'react';
import { useState, useEffect } from 'react';
import { Genre } from '../../../../types/genre';
import { generateGenre } from '../../../../services/genre-service';


function GenreGenerator() {

  const [genreResult, setGenreResult] = useState<Genre>();

  function generate() {
    const genreResult = generateGenre();
    console.log('genre generate result', JSON.stringify(genreResult));
    setGenreResult(genreResult);
  }

  return (
    <div>
      <h2>Genre</h2>

      <input placeholder="Generate" type="button" onClick={generate} value='Generate'/> 


      { genreResult && 
 
            <div>

              <h2>{genreResult.name}</h2>

              <p>Trigger: {genreResult.trigger}</p>
              <p>Event: {genreResult.event}</p>
              <p>Result: {genreResult.result}</p>

              <p>Opposition: {genreResult.oppositionType}</p>

              <p>Escalations: {genreResult.values[0]} / {genreResult.values[1]}</p>

              <p>Cast: {genreResult.cast.join(', ')}</p>

              <p>Emotion: {genreResult.emotion}</p>

            </div>

     }


    </div>
  );
}

export default GenreGenerator;
