import React from 'react';
import { getRaisingGroup } from '../../../services/raising-group-service';
import { RaisingGroup } from '../../../types/raising-group';
import { useState, useEffect } from 'react';
import NavigationMenu from '../../Plugins/Navigation/NavigationMenu';

function RaisingGroupPage() {
 
  const [raisingGroup, setRaisingGroup] = useState<RaisingGroup>();

  const dataFetch = () => {
    return getRaisingGroup();
  };

  useEffect(() => {
    const raisingGroup = dataFetch();
    console.log('raising group', JSON.stringify(raisingGroup));
    setRaisingGroup(raisingGroup);
  }, []);
 
  return (
    <div className="Situations">
        <NavigationMenu />
        <h1>Raising Group</h1>

        <h2>Types</h2>
        <ul className='mainList'>
        {
            raisingGroup?.groups.map(group => {
              return <li key={group.name}>
                
                {group.name}
              
              </li>
            })
        }
        </ul>

        <h2>Aspects</h2>
        <ul className='mainList'>
        {
            raisingGroup?.aspects.map(aspect => {
              return <li key={aspect.a}>
                
                {aspect.a} / {aspect.b}
              
              </li>
            })
        }
        </ul>

    </div>
  );
}

export default RaisingGroupPage;
