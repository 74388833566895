import { Genre } from "../types/genre";

const genreData: Genre[] = [
    {
        name: 'Ancient / Action Epic',
        oppositionType: 'Physical Forces',
        trigger: 'Know someone is coming for you',
        values: ['Life', 'Death'],
        cast: ['Hero', 'Villain', 'Victim'],
        event: 'Hero at mercy of Villain',
        result: 'Saviour',
        emotion: 'Excitement'
    },
    {
        name: 'Horror Story',
        oppositionType: 'Physical Forces',
        trigger: 'Sense something is coming for you',
        values: ['Survival', 'Damnation'],
        cast: ['Monster', 'Victim'],
        event: 'Victim at mercy of Monster',
        result: '',
        emotion: 'Irrational Terror'
    },
    {
        name: 'War',
        oppositionType: 'Social Forces',
        trigger: 'Battle is imminent',
        values: ['Victory', 'Defeat'],
        cast: ['Soldier', 'Enemy'],
        event: 'Decisive Battle',
        result: '',
        emotion: 'Harrowing Fear'
    },
    {
        name: 'Political',
        oppositionType: 'Social Forces',
        trigger: 'Someone will take away your power',
        values: ['Power', 'Weakness'],
        cast: ['Combatant A', 'Combatant B'],
        event: 'Power won / lost',
        result: 'Unexpecetdly win/lose, new contest',
        emotion: 'Hunger for victory'
    },
    {
        name: 'Crime',
        oppositionType: 'Social Forces',
        trigger: 'Something has been done, about to be done',
        values: ['Justice', 'Injustice'],
        cast: ['Cop', 'Criminal', 'Victim'],
        event: 'Exposure of criminal',
        result: '',
        emotion: 'Suspense'
    },
    {
        name: 'Modern Epic',
        oppositionType: 'Social Forces',
        trigger: 'Tyrant comes for you',
        values: ['Tyranny', 'Freedom'],
        cast: ['Tyrant', 'Rebel'],
        event: 'Act of rebellion',
        result: '',
        emotion: 'Moral Outrage'
    },
    {
        name: 'Social Drama',
        oppositionType: 'Social Forces',
        trigger: 'Problem becomes focused (solution/disaster)',
        values: ['Problem', 'Solution'],
        cast: ['Social Leader', 'Victim'],
        event: 'Problem reaches crisis',
        result: '',
        emotion: 'Moral Indignation'
    },
    {
        name: 'Enterprise',
        oppositionType: 'Social Forces',
        trigger: 'Plan to get something',
        values: ['Success', 'Failure'],
        cast: ['Protagonist', 'Social Institution'],
        event: 'Professional loss',
        result: 'get/lose, something expect, something dont expect',
        emotion: 'Rooting for success'
    },
    {
        name: 'Family',
        oppositionType: 'Personal Relationships',
        trigger: 'Opportunities to commit to each other',
        values: ['Unity', 'Breakup'],
        cast: ['Family Member A', 'Family Member B'],
        event: 'Crisis of Devotion',
        result: '',
        emotion: 'Longing for togetherness'
    },
    {
        name: 'Love',
        oppositionType: 'Personal Relationships',
        trigger: 'Opportunity to get true love',
        values: ['Lasting Love', 'Lost Love'],
        cast: ['Lover A', 'Lover B'],
        event: 'Act of Love',
        result: 'They themselves react unexpectedly',
        emotion: 'Longing for Love'
    },

];

export { genreData };