import React, { useContext } from 'react';
import { Need } from '../../../../types/need';
import { useState, useEffect } from 'react';
import { generateNeed } from '../../../../services/needs-service';
import { GeneratorContext } from '../../../../store/generator-context';

interface SingleNeedGeneratorComponentProps {
   heading: string
}

function SingleNeedGenerator(props: SingleNeedGeneratorComponentProps) {

  const [singleNeedResult, setSingleNeedResult] = useState<Need>();


  function generate() {
    const singleNeedResult = generateNeed();
    console.log('Single Need generate result', JSON.stringify(singleNeedResult));
    setSingleNeedResult(singleNeedResult);
  }

  return (
    <div>
      <h2>{props.heading}</h2>


      <input placeholder="Generate" type="button" onClick={generate} value='Generate'/> 

      { singleNeedResult?.name &&
            (
              <div>
               <p>{singleNeedResult?.name} ({singleNeedResult?.category})</p>
             </div>
            )
      }


    </div>
  );
}

export default SingleNeedGenerator;
