import { Sacrifice } from "../types/sacrifice";

const sacrificeData: Sacrifice[] = [
   {
      victim: 'Protagonist',
      description: 'Protagonist was sacrificed to meet the challenge.',
      issues: [
        {
            problem: 'hiding from those who sacrificed him',
            solution: 'face them'
        }
      ]
   },
   {
      victim: 'Another',
      description: 'Another was sacrificed to protect the Protagonist and to meet the challenge,',
      issues: [
        {
            problem: 'hiding from what truly wants to live up to it',
            solution: 'accept what truly wants'
        }
      ]
   }

];

export { sacrificeData };