import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import SituationsPage from '../Pages/Situations/SituationsPage';
import NeedsPage from '../Pages/Needs/NeedsPage';
import RaisingGroupPage from '../Pages/RaisingGroup/RaisingGroupPage';
import GeneratorPage from '../Pages/Generator/GeneratorPage';
import GenresPage from '../Pages/Genres/GenresPage';

function App() {
  return (
    <div className="App">
      <Routes>
            <Route path="/" element={<GeneratorPage />} />
            <Route path="/group" element={<RaisingGroupPage />} />
            <Route path="/situations" element={<SituationsPage />} />
            <Route path="/needs" element={<NeedsPage />} />
            <Route path="/genres" element={<GenresPage />} />
      </Routes>
    </div>
  );
}

export default App;
