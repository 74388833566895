import { Situation } from '../types/situation'

const situationsData: Situation[] = [
    {
        name: 'Supplication',
        players: ['Persecutor', 'Supplicant', 'Power in authority whose decision is doubtful'],
        scenarios: []
    },
    {
        name: 'Deliverance',
        players: ['Unfortunate', 'Threatener', 'Rescuer'],
        scenarios: []
    },
    {
        name: 'Crime pursued by vengeance',
        players: ['Avenger', 'Criminal'],
        scenarios: []
    },
    {
        name: 'Vengenace taken for kindred upon Kindred',
        players: ['Avenging Kinsman', 'Guilty Kinsman', 'Relative of both'],
        elements: ['Remembrnace of the Victim'],
        scenarios: []
    },
    {
        name: 'Pursuit',
        players: ['Fugitive'],
        elements: ['Punishment'],
        scenarios: []
    },
    {
        name: 'Disaster',
        players: ['Vanquished Power', 'Victorious Enemy', 'Messenger'],
        scenarios: []
    },
    {
        name: 'Falling prey to cruelty or misfortune',
        players: ['Unfortunate', 'Master'],
        elements: ['Misfortune'],
        scenarios: []
    },
    {
        name: 'Revolt',
        players: ['Tyrant', 'Conspirator'],
        scenarios: []
    },
    {
        name: 'Daring Enterprise',
        players: ['Bold Leader', 'Adversary'],
        elements: ['Object'],
        scenarios: []
    },
    {
        name: 'Abduction',
        players: ['Abductor', 'Abducted', 'Guardian'],
        scenarios: []
    },



];

export { situationsData };