import React from 'react';
import { useState, useEffect } from 'react';
import NavigationMenu from '../../Plugins/Navigation/NavigationMenu';
import { Genre } from '../../../types/genre';
import { getAllGenres } from '../../../services/genre-service';

function GenresPage() {
 
  const [genres, setGenres] = useState<Genre[]>([]);

  const dataFetch = () => {
    return getAllGenres();
  };

  useEffect(() => {
    const genres = dataFetch();
    console.log('genres', JSON.stringify(genres));
    setGenres(genres);
  }, []);
 
  return (
    <div className="Situations">
        <NavigationMenu />
        <h1>Genres</h1>
        <ul className='mainList'>
        {
            genres.map(genre => {
              return <li key={genre.name}>
                
                <h2>{genre.name}</h2>

                <p>Trigger: {genre.trigger}</p>
                <p>Event: {genre.event}</p>
                <p>Result: {genre.result}</p>

                <p>Opposition: {genre.oppositionType}</p>

                <p>Escalations: {genre.values[0]} / {genre.values[1]}</p>

                <p>Cast: {genre.cast.join(', ')}</p>

                <p>Emotion: {genre.emotion}</p>
              
              </li>
            })
        }
        </ul>

    </div>
  );
}

export default GenresPage;
