import { Event } from '../types/event'
import { getAllSituations } from './situations-service';
import { getRandomPosition } from '../utils/number-utils';

function generateEvent(): Event {
  const allSituations = getAllSituations();
  
  const firstPosition = getRandomPosition(allSituations.length);

  let secondPosition = getRandomPosition(allSituations.length);

 
  while(secondPosition === firstPosition) {
    secondPosition = getRandomPosition(allSituations.length);
  }
 

  return {
    situations: [
        allSituations[firstPosition], allSituations[secondPosition]
    ]
  }

}

export { generateEvent };