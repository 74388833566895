
import { needsData } from '../data/needs-data'; 
import { NeedCategory, Need } from '../types/need';
import { getRandomPosition } from '../utils/number-utils';

function getAllNeeds(): NeedCategory[] {
   console.log('in needs', needsData.length);
   return needsData;
}

function generateNeed(): Need {

   const needsList = getNeedsList();

   const position = getRandomPosition(needsList.length);
   console.log('position=', position);

   return needsList[position];
}


function getNeedsList(): Need[] {
   const needsList: Need[] = [];
   const allNeeds: NeedCategory[] = getAllNeeds();

   allNeeds.forEach(needCategory => {
       needCategory.needs.forEach((need) => {
         needsList.push({
            name: need.name,
            category: needCategory.name
         });

       });
   });

   console.log(needsList);

   return needsList;

}


export { getAllNeeds, generateNeed};

