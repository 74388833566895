import React from 'react';
import { getAllNeeds } from '../../../services/needs-service';
import { NeedCategory } from '../../../types/need';
import { Need } from '../../../types/need';
import { useState, useEffect } from 'react';
import NavigationMenu from '../../Plugins/Navigation/NavigationMenu';

function NeedsPage() {
 
  const [needCategories, setNeedCategories] = useState<NeedCategory[]>([]);

  const dataFetch = () => {
    return getAllNeeds();
  };

  useEffect(() => {
    const needCategories = dataFetch();
    console.log('needs', JSON.stringify(needCategories));
    setNeedCategories(needCategories);
  }, []);
 
  return (
    <div className="Situations">
        <NavigationMenu />
        <h1>Needs</h1>
        <ul className='mainList'>
        {
            needCategories.map(category => {
              return <li key={category.name}>
                
                <h2>{category.name}</h2>

                <ul className='innerList'>
                    {
                      category.needs.map(need => {
                        return <li key={need.name}>
                          {need.name}
                        </li> 

                      })
                    }
                </ul>
              
              </li>
            })
        }
        </ul>

    </div>
  );
}

export default NeedsPage;
