import React from 'react';
import { Link } from "react-router-dom";
import './NavigationMenu.css';

function NavigationMenu() {
  return (
    <div className="NavigationMenu">
        <nav>
            <ul>
                <li>
                    <Link to="/">Generator</Link>
                </li>
                <li>
                    <Link to="/group">Raising Group</Link>
                </li>
                <li>
                    <Link to="/needs">Needs</Link>
                </li>
                <li>
                    <Link to="/situations">Situations</Link>
                </li>
                <li>
                    <Link to="/genres">Genres</Link>
                </li>
            </ul>
        </nav>
    </div>

  );
}

export default NavigationMenu;
