import React from 'react';
import { RaisingGroupGenerateResult } from '../../../../types/raising-group';
import { useState, useEffect } from 'react';
import { generateRaisingGroup } from '../../../../services/raising-group-service';

function RaisingGroupGenerator() {

  const [raisingGroupResult, setRaisingGroupResult] = useState<RaisingGroupGenerateResult>();

  function generate() {
    console.log('Raisning Group Generatot button cliced');
    const raisingGroupResult = generateRaisingGroup();
    console.log('raising group generate result', JSON.stringify(raisingGroupResult));
    setRaisingGroupResult(raisingGroupResult);
  }

  return (
    <div>
      <h2>Raising Group</h2>

      <input placeholder="Generate" type="button" onClick={generate} value='Generate'/> 
      
      { raisingGroupResult &&
          (
            <div>
            <p>{raisingGroupResult?.group.name}</p>
            <p>{raisingGroupResult?.aspects.join(', ')}</p>
            </div>
          )
      }

      <p>To do: add methods</p>

      <p>to do: One of these aspects comes under attack</p>


    </div>
  );
}

export default RaisingGroupGenerator;
