import { NeedCategory } from '../types/need';

const needsData: NeedCategory[] = [
    {
        name: 'Esteem',
        needs: [
            {
                name: 'Recognition'
            },
            {
                name: 'Strength'
            },
            {
                name: 'Freedom'
            },
            {
                name: 'Respect'
            },
            {
                name: 'Self-esteem'
            },
            {
                name: 'Status'
            }   
        ]
    },
    {
        name: 'Love, Belonging',
        needs: [
            {
                name: 'Friendship'
            },
            {
                name: 'Intimacy'
            },
            {
                name: 'Family'
            },
            {
                name: 'Connection'
            },
            {
                name: 'Love'
            }
        ]
    },
    {
        name: 'Safety',
        needs: [
            {
                name: 'Security'
            },
            {
                name: 'Employment'
            },
            {
                name: 'Resources'
            },
            {
                name: 'Health'
            },
            {
                name: 'Prosperity'
            }
        ]
    },
    {
        name: 'Physiological',
        needs: [
            {
                name: 'Air'
            },
            {
                name: 'Water'
            },
            {
                name: 'Shelter'
            },
            {
                name: 'Food'
            },
            {
                name: 'Sleep'
            },
            {
                name: 'Clothing'
            },
            {
                name: 'Reproduction'
            }
        ]
    },

];

export { needsData };