import React from 'react';
import { Event } from '../../../../types/event';
import { useState, useEffect } from 'react';
import { generateEvent } from '../../../../services/event-service';


interface EventGeneratorProps {
  heading: string
}

function EventGenerator(props: EventGeneratorProps) {

  const [eventResult, setEventResult] = useState<Event>();

  function generate() {
    const eventResult = generateEvent();
    console.log('event generate result', JSON.stringify(eventResult));
    setEventResult(eventResult);
  }

  return (
    <div>
      <h2>{props.heading}</h2>

      <input placeholder="Generate" type="button" onClick={generate} value='Generate'/> 


      { eventResult?.situations?.length && eventResult?.situations?.length > 0 &&
 
            <div>

                <h2>{eventResult.situations[0].name} results in {eventResult.situations[1].name}</h2>

                <ul className='mainList'>
                        {
                            eventResult?.situations.map(situation => {
                              return <li key={situation.name}>
                                
                                {situation.name}
                              
                              </li>
                            })
                        }
                </ul>

            </div>

     }


    </div>
  );
}

export default EventGenerator;
