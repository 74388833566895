import React from 'react';
import { useState } from 'react';
import NavigationMenu from '../../Plugins/Navigation/NavigationMenu';
import EventGenerator from '../../Plugins/Generation/EventGenerator/EventGenerator';
import GenreGenerator from '../../Plugins/Generation/GenreGenerator/GenreGenerator';
import Background from './Background';
import { GeneratorContext } from '../../../store/generator-context';

function GeneratorPage() {

  const [text, setText] = useState('');
 
 
  return (
    <div className="Situations">
      <GeneratorContext.Provider value={{
        text,
        setText
      }}>
          <NavigationMenu />
          <h1>Generator</h1>
          <Background />
          <br />
          <GenreGenerator />
          <br />
          <EventGenerator  heading='Event' />
      </GeneratorContext.Provider> 
    </div>
  );
}

export default GeneratorPage;
