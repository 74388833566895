import { RaisingGroup } from '../types/raising-group';

const raisingGroupData: RaisingGroup = {
        groups : [
            {
                name: 'Family'
            },
            {
                name: 'Resistance'
            },
            {
                name: 'School'
            }
        ],
        aspects: [
            {
                a: 'Legal',
                b: 'Illegal'
            },
            {
                a: 'Respected',
                b: 'Joke'
            },
            {
                a: 'Work',
                b: 'Casual'
            },
            {
                a: 'Top Dogs',
                b: 'Underdogs'
            }
        ]
 };

export { raisingGroupData };