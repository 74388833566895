import React from 'react';
import NavigationMenu from '../../Plugins/Navigation/NavigationMenu';
import RaisingGroupGenerator from '../../Plugins/Generation/RaisingGroupGenerator/RaisingGroupGenerator';
import SingleNeedGenerator from '../../Plugins/Generation/SingleNeedGenerator/SingleNeedGenerator';
import EventGenerator from '../../Plugins/Generation/EventGenerator/EventGenerator';
import GenreGenerator from '../../Plugins/Generation/GenreGenerator/GenreGenerator';
import SacrificeGenerator from '../../Plugins/Generation/SacrificeGenerator/SacrificeGenerator';

function Background() {
 
 
  return (
    <div className="Background">

      <h2>Background</h2>
      <RaisingGroupGenerator />
      <br />
      <SingleNeedGenerator heading='Need focused on (challenge)' />
      <br />
      <SingleNeedGenerator heading='Need sacrificed'/>
      <br />

      <h2>Theme and Irreconcilable Problem</h2>
      <p>The theme is concerned with which of these 2 values is correct. The Protagonist will face this same
         challenge and have to decide each time which value to choose. There must be a progression over the 
         course of the story.
      </p>
      <p>The Stakes character must also make this choice - but their background experience is the opposite side of these values.
         This allows us to explore the good and bad aspects of each of these values.
      </p>

      <p>Throughout the story these values seem incompatible. This is the Irreconcilable Problem.
         What the Protagoist must find at the end is a way to reconcile them. Why this seems impossible at the start is due to 
         the ongoing impacts of the Sacrifice Event in the past. The past has created a red line they just wont cross. 
         The solution lies in crossing this line.</p>

      <p>What he will not do is what he needs to do to face the sacrifice problem. This is his red line at the start.
         This refusal represents a rejection of one of the values at the start. This is their starting point which may 
         be in agreement or disagreement with the sacrifice decision choice of their raising group.</p>

      <br />
      <SacrificeGenerator />
       <br />
      <EventGenerator heading='Event which triggered the Sacrifice' />
    </div>
  );
}

export default Background;
