import { raisingGroupData } from '../data/raising-group-data'; 
import { RaisingGroup, RaisingGroupGenerateResult } from '../types/raising-group';
import { getRandomPosition, randomIntFromInterval } from '../utils/number-utils';
import { Aspect } from '../types/raising-group';

function getRaisingGroup(): RaisingGroup {
   return raisingGroupData;
}

function generateRaisingGroup(): RaisingGroupGenerateResult {
   const raisingGroup = getRaisingGroup();

   const groupPosition = getRandomPosition(raisingGroup.groups.length);

   return {
      group: raisingGroup.groups[groupPosition],
      aspects: generateAspects(raisingGroup.aspects)
   }
}

function generateAspects(aspects: Aspect[]): string[]{

   const arr: string[] = [];

   aspects.forEach((aspect) => {
     const randomPosition = randomIntFromInterval(0, 1);
     if (randomPosition === 0) {
         arr.push(aspect.a);
     } else {
         arr.push(aspect.b);
     }
   });


   return arr;

}


export { getRaisingGroup, generateRaisingGroup };

