import { Sacrifice } from '../types/sacrifice';
import { sacrificeData } from '../data/sacrifice-data';
import { getRandomPosition } from '../utils/number-utils';


function generateSacrifice(): Sacrifice {
    const sacrifice = sacrificeData[getRandomPosition(sacrificeData.length)];
    return { 
        ...sacrifice,
        mainIssue: sacrifice.issues[getRandomPosition(sacrifice.issues.length)]
    } ;
}


export { generateSacrifice }