import React from 'react';
import { Need } from '../../../../types/need';
import { useState, useEffect } from 'react';
import { generateNeed } from '../../../../services/needs-service';
import { Sacrifice } from '../../../../types/sacrifice';
import { generateSacrifice } from '../../../../services/sacrifice-service';

function SacrificeGenerator() {

  const [sacrificeResult, setSacrificeResult] = useState<Sacrifice>();

  function generate() {
    const sacrificeResult = generateSacrifice();
    console.log('Sacrifice generate result', JSON.stringify(sacrificeResult));
    setSacrificeResult(sacrificeResult);
  }

  return (
    <div>
      <h2>Sacrifice</h2>

      <input placeholder="Generate" type="button" onClick={generate} value='Generate'/> 

      { sacrificeResult?.victim &&
            (
              <div>
               <p>{sacrificeResult.description}</p>
               <p>The Protagonist is running from this decision by {sacrificeResult.mainIssue?.problem}. 
               This is the rubber band that destroys all his plans.</p>
               <p>What he needs to do is {sacrificeResult.mainIssue?.solution}.</p>
             </div>
            )
      }


    </div>
  );
}

export default SacrificeGenerator;
