
import { situationsData } from '../data/situations-data'; 
import { Situation } from '../types/situation';

function getAllSituations(): Situation[] {
   console.log('in situation', situationsData.length);
   return situationsData;
}


export { getAllSituations };

