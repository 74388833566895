import React from 'react';
import { getAllSituations } from '../../../services/situations-service';
import { Situation } from '../../../types/situation';
import { useState, useEffect } from 'react';
import NavigationMenu from '../../Plugins/Navigation/NavigationMenu';
import './SituationsPage.css';


function SituationsPage() {
 
  const [situations, setSituations] = useState<Situation[]>([]);

  const dataFetch = () => {
    return getAllSituations();
  };

  useEffect(() => {
    const situations = dataFetch();
    console.log('situations', JSON.stringify(situations));
    setSituations(situations);
  }, []);
 
  return (
    <div className='SituationsDisplayComponent'>
        <NavigationMenu />
        <h1>Situations</h1>
        <ul className='mainList'>
        {
            situations.map(situation => {
              return <li className='SituationHeading' key={situation.name}>
                
              <h2>{situation.name}</h2> 
        
              <h3>Players</h3>
              <ul className='innerList'>
                    {
                      situation.players.map(player => {
                        return <li key={player}>
                          {player}
                        </li> 
                      })
                    }
                </ul>

              <h3>Scenarios</h3>
              <ul className='innerList'>
                    {
                      situation.scenarios.map(scenario => {
                        return <li key={scenario}>
                          {scenario}
                        </li> 
                      })
                    }
                </ul>
         
              
              </li>;
            })
        }
        </ul>

    </div>
  );
}

export default SituationsPage;
